import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import {navLinks} from '../data'
import {logo, menublack, closeblack} from '../assets'
// import { Fragment } from 'react'
// import { Menu, Transition } from '@headlessui/react'

// import {MdMenu, MdClose} from 'react-icons/md'

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

const Navbar = () => {
	const [toggle, setToggle] = useState(false)
	return (

    <nav className='w-full flex p-8 md:px-28 h-[12vh] z-10 justify-between items-center navbar shadow-sm'>
		<Link to='/'><img src={logo} alt='wash-logo' className='w-[60px]'  /></Link>
		<ul className='list-none sm:flex hidden justify-end items-center flex-1'>
		{/* Our Stores */}
		{/* <Menu as="div" className="relative inline-block text-[16px] font-jakarta mr-10 gap-3">
			<div>
				<Menu.Button className="inline-flex w-full text-[16px] font-jakarta font-semibold text-black transition-all duration-200 hover:text-orange-600 hover:border-b-[1px] hover:border-b-orange-600 pb-2">
					Stores
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						<Menu.Item>
							{({ active }) => (
								<p
									className={classNames(
										active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'block px-4 py-2 text-[16px] font-jakarta'
									)}>
									Crossroads Mall, Karen
								</p>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<p
								className={classNames(
									active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
									'block px-4 py-2 text-[16px] font-jakarta'
								)}>
								Remax Mall, Argwings Khodek Rd.
								</p>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<p
								className={classNames(
									active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
									'block px-4 py-2 text-[16px] font-jakarta'
								)}>
								105 Mummydadas, James Gichuru Rd.
								</p>
							)}
						</Menu.Item>
					</div>
				</Menu.Items>
			</Transition>
		</Menu> */}

        {navLinks.map((nav, index) => (
          <li key={nav.id} className={`font-jakarta transition-all duration-200 hover:text-orange-600 hover:border-b-[1px] hover:border-b-orange-600 pb-2 font-semibold cursor-pointer text-[16px] ${index === navLinks.length-1 ? 'mr-0' : 'mr-10'} text-black`}>
            <Link to={`/${nav.id}`}>
              {nav.title}
            </Link>
          </li>
        ) )}
    </ul>
      
    {/* nav burger implementation */}
    <div className='sm:hidden flex flex-1 justify-end items.center'>
        <img src={toggle ? closeblack : menublack} alt="menu-burger" className='w-8 object-contain' onClick={() => setToggle((prev) => !prev)} />
        <div className={`${toggle ? 'flex' : 'hidden'} p-6 bg-orange-500 absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`} >
            <ul className='list-none flex flex-col  justify-end items-start flex-1'>
            {/* Our Stores */}
            {/* <Menu as="div" className="relative inline-block text-[16px] font-jakarta">
              	<div>
					<Menu.Button className="inline-flex w-full mb-4 text-[16px] font-jakarta font-semibold text-white">
						Stores
					</Menu.Button>
      			</div>

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<div className="py-1">
							<Menu.Item>
								{({ active }) => (
									<p
										className={classNames(
											active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
											'block px-4 py-2 text-[16px] font-jakarta'
										)}>
										Crossroads Mall, Karen
									</p>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<p className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-[16px] font-jakarta')}>Remax Mall, Argwings Khodek Rd.</p>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<p className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700','block px-4 py-2 text-[16px] font-jakarta')}>105 Mummydadas, James Gichuru Rd.</p>
								)}
							</Menu.Item>
						</div>
					</Menu.Items>
				</Transition>
    		</Menu> */}
            {navLinks.map((nav, index) => (
              <li key={nav.id} className={`font-jakarta hover:text-white font-semibold cursor-pointer text-[16px] ${index === navLinks.length-1 ? 'mb-0' : 'mb-4'} text-gray-100`}>
                <Link to={`/${nav.id}`}>
                  {nav.title}
                </Link>
              </li>
          ) )}
        </ul>
          </div>
      </div>

    </nav>
  )
}

export default Navbar