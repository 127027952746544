import React from 'react'
import {Navbarwhite, PriceList, PricingHero, Footer } from '../components'

const Pricing = () => {
  return (
    <>
      <Navbarwhite />
      <PricingHero/>
      <PriceList/>
      <Footer/>
    </>

  )
}

export default Pricing