import React from 'react'
import { DryCleaningHero, Footer, Navbarwhite } from '../components'

const DryCleaning = () => {
  return (
    <>
        <Navbarwhite/>
        <DryCleaningHero />
        <Footer/>
    </>
  )
}

export default DryCleaning