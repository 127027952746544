import React from 'react'
import { LaundryCleaningContent, LaundryCleaningHero, Navbarwhite,Footer } from '../components'

const LaundryCleaning = () => {
  return (
    <>
        <Navbarwhite/>
        <LaundryCleaningHero/>
        <LaundryCleaningContent/>
        <Footer/>
    </>
  )
}

export default LaundryCleaning