import React from 'react'
import { Link } from 'react-router-dom'

const Monthlyplans = () => {
  return (
    <>
        <div className="mb-5 lg:mb-10">
            <h3 className='font-quicksand font-extrabold text-[30px] md:text-[36px] mb-5 '>Our <span className='text-orange-500 font'>monthly</span> plans.</h3>
        </div>
        {/* Grid system */}
        <div className="flex flex-col lg:flex-row items-center justify-center my-3 gap-5 w-full">
            <Link to='/services/wash-kila-kitu' className="relative shadow-lg rounded-lg mb-5 w-full h-[50vh]">
                <img src="https://res.cloudinary.com/do3idyzar/image/upload/v1672910423/wash-woman-with-laundry-basket_o7rhdm.jpg" alt="wash dry cleaning" className="w-full h-full object-cover rounded-lg" />
                <div className="absolute top-0 left-0 w-full h-full rounded-lg bg-black bg-opacity-30 p-5 flex flex-col justify-end hover:bg-opacity-80 transition-all duration-300 ease-in ">
                    <h4 className="text-orange-500 font-quicksand font-bold text-[30px]">Wash Kila Kitu</h4>
                    <p className="text-white text-[16px] text-justify line-clamp-3">Sometimes you feel like throwing in the towel but that just means more laundry. Wash Kila  Kitu takes care of all your needs delivering your laundry ironed, put in hangers and well packaged in laundry bags.</p>
                </div>
            </Link>

            <Link to='/services/wash-and-fold' className="relative shadow-lg rounded-lg mb-5 w-full h-[50vh]">
                <img src="https://res.cloudinary.com/do3idyzar/image/upload/v1672910407/wash-pricing-hero_c97dfl.jpg" alt="wash dry cleaning" className="w-full h-full object-cover rounded-lg" />
                <div className="absolute top-0 left-0 w-full h-full rounded-lg bg-black bg-opacity-30 p-5 flex flex-col justify-end hover:bg-opacity-80 transition-all duration-300 ease-in ">
                    <h4 className="text-orange-500 font-quicksand font-bold text-[30px]">Wash & Fold</h4>
                    <p className="text-white text-[16px] text-justify line-clamp-3">The ideal service if you want to avoid the dreaded laundry chore and save your time and sanity. We do your laundry for you so you can enjoy your free time. Whether it’s a weeks worth of the entire family’s clothes, or all your linens, we pick up, wash and fold your laundry.</p>
                </div>
            </Link>

        </div>
    </>
  )
}

export default Monthlyplans