// import logo from '../wash logo.svg'
import {Hero, Advantages, Schedule, Location, Faq,Footer } from '../components'

const Home = () => {
  return (
    <>
      <Hero/>
      <Advantages />
      <Schedule/>
      <Location/>
      <Faq/>
      <Footer/>
    </>
  )
}

export default Home