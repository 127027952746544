import { mainServicesHeroData } from '../data'

const ServicesHero = () => {
  return (
    <div className='flex flex-col justify-center items-center flex-wrap px-8 pt-8 sm:px-28'>
        
        {mainServicesHeroData.map((data, index) => (
            <div key={data.id} className='flex lg:justify-between flex-col-reverse py-5 md:flex-row w-full items-center'>
                <div className='mb-5 lg:w-2/5'>
                    <h1 className='text-[36px] lg:text-[48px] my-8 lg:mb-8 font-extrabold text-orange-500 leading-[50px]'>Wash Dry 
                    <span className='block text-black'> Cleaners Services.</span></h1>
                    <p className='font-jakarta text-justify text-[16px]'>{data.content}</p>
                </div>
                <div className='mb-5'>
                    <img src={data.url} className="rounded-lg shadow-xl object-cover lg:min-h-[70vh] min-h-[40vh] lg:max-w-[40vw] transition-all duration-500 hover:scale-105" alt="wash-services" />
                </div>
            </div>
        ))}
    </div>
            
    
  )
}

export default ServicesHero
