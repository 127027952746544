import React from 'react'
import { laundryCleaningServices } from '../data'
import { Link } from 'react-router-dom'
import {BsArrowLeftCircle} from 'react-icons/bs'

const LaundryCleaningContent = () => {
  return (
    <div className='flex flex-col px-8 sm:px-28 justify-center items-center flex-wrap my-5'>
        <h2 className='font-extrabold text-[36px]'>Our laundry <span className='text-fuchsia-600'>cleaning services.</span></h2>

        {laundryCleaningServices.map((service, index) => (
            <div key={service.id} className={`flex flex-col items-center lg:flex-row lg:justify-around my-10 lg:m-16 ${index%2 ===1 ? 'lg:flex-row-reverse' : ''}`}>
                <div className={`w-full mb-5 justify-end ${index%2 ===1 ? 'lg:pl-20' : ''}`}>
                    <img src={service.url} alt="wash laundry service" className='rounded-lg shadow-lg object-cover w-[350px] h-[300px] transition-all duration-500 hover:scale-105' />
                </div>
                <div className={`w-full ${index%2 ===1 ? 'lg:pr-20' : ''}`}>
                    <h3 className='text-fuchsia-600 font-extrabold text-[24px]'>{service.title}</h3>
                    {service.content.map((p) => (
                        <p key={p.id} className='text-justify font-jakarta text-[14px] '>{p.paragraph}</p>
                    ))}
                </div>
            </div>
        ))}
        <h4 className='font-windsong text-fuchsia-600 text-[18px] lg:text-[30px] font-extrabold text-center leading-6'>A new and entirely non-toxic process <span className='font-bold block text-black text-[24px] lg:text-[42px]'>brought to you by Wash!</span></h4>

        <Link to="/services" className="bg-fuchsia-600 p-4 text-white text-[16px] font-jakarta rounded-lg hover:scale-105 transition-all duration-200 my-10 flex items-center">
            <BsArrowLeftCircle className="text-[20px] text-white mr-2"/>
            Back to Services
        </Link>

    </div>
  )
}

export default LaundryCleaningContent