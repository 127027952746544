import { Navbarwhite, Footer } from "../components"
import { washKilaKituPricing } from "../data"
import { Link } from 'react-router-dom'
import {BsArrowLeftCircle} from 'react-icons/bs'

const WashKilaKitu = () => {
  return (
    <>
        <Navbarwhite/>
        <div className="flex flex-col justify-center items-center flex-wrap mt-20">
            <div className="px-8 lg:px-28">
                <h3 className="font-extrabold text-[36px]">Wash <span className="text-orange-500">Kila </span>Kitu</h3>
                <p className="text-justify font-jakarta lg:w-1/2 text-[16px]">Sometimes you feel like throwing in the towel but that just means more laundry. Wash Kila  Kitu takes care of all your needs delivering your laundry ironed, put in hangers and well packaged in laundry bags.</p>
            </div>
            <div className='grid lg:gap-0 lg:grid-cols-2 lg:w-full lg:grid-rows-2 mt-20 '>
                {washKilaKituPricing.map((plan, index) => (
                    <div key={plan.id} className={`${index === 0 ? 'bg-orange-200 ' : ''} ${index === 1 ? 'bg-fuchsia-800 text-white' : ''} ${index === 2 ? 'bg-rose-800 text-white' : ''} ${index === 3 ? 'bg-orange-500 text-white' : ''} p-6 px-20 lg:px-36 flex flex-col justify-center items-start font-jakarta`}>
                        <h3 className="font-extrabold text-[20px] mb-10">{plan.title}</h3>
                        {plan.features.map((data, index) => (
                            <div key={data.id} >
                                <p className="text-[16px]">{data.name}</p>
                            </div>
                        ))}
                        <p className="text-[20px] font-medium mt-10">{plan.price}</p>
                    </div>
                ))}
            </div>
            <Link to="/services" className='bg-orange-500 p-4 text-white text-[16px] font-jakarta rounded-lg hover:scale-105 transition-all duration-200 my-10 flex justify-center items-center'>
                <BsArrowLeftCircle className="text-[20px] text-white mr-2"/>
                Back to Services
            </Link>
        </div>
        <Footer/>
    </>
  )
}

export default WashKilaKitu