const PricingHero = () => {
    return (
        <div className='flex flex-col justify-center items-center flex-wrap px-8 pt-10 lg:pt-0 sm:px-28 lg:px-0 mb-10'>
            <div className="flex flex-col-reverse lg:justify-between lg:flex-row w-full items-center">
                <div className="lg:pl-28">
                    <h1 className="text-[36px] lg:text-[54px] font-extrabold ">Simple,<span className="block text-orange-500"> transparent</span> <span> pricing</span> for you</h1>
                    <p className="font-jakarta text-justify text-[16px]">Our prices are meticulously priced to suit all your laundry needs</p>
                </div>
                <div className="mb-5  lg:mb-0">
                    <img src='https://res.cloudinary.com/do3idyzar/image/upload/v1674479968/wash-price-tag_fsxccv.jpg' className='rounded-lg lg:rounded-tr-none lg:rounded-br-none shadow-sm object-cover w-[90vw] h-[50vh] lg:h-[88vh] lg:w-[50vw]' alt="wash pricing" />
                </div>
            </div>

        </div>
    )
}

export default PricingHero;