import {MdAssignmentTurnedIn} from "react-icons/md"
import {GiWallet} from "react-icons/gi"
import {BiHappyHeartEyes} from "react-icons/bi"
import {RiTimerFlashFill} from 'react-icons/ri'
import { advantagesParagraphs } from "../data"

const Advantages = () => {
  return (
    <div className='flex flex-wrap sm:justify-around justify-center items-center p-10 sm:px-36 lg:py-10'>
        <div className='flex flex-col lg:w-2/5 mb-10 '>
            <h3 className='font-quicksand font-extrabold text-[24px] md:text-[36px] mb-5'>For us, it’s all about delivering authentic <span className='text-orange-500'>Dry Cleaning</span>  and <span className='text-orange-500'>Laundry Services</span> everyday.</h3>
            {advantagesParagraphs.map((paragraph, index) => (
              <p key={paragraph.id} className='font-jakarta text-black text-[16px] mb-5  text-justify'>
                {paragraph.content}
              </p>
            )
            )}
            {/* <p className='font-jakarta text-black text-sm text-justify'>For as long as people need their laundry cleaned, we’ll keep building our footprint and providing more easily located stores and a level of service that only a nationwide brand can produce.</p> */}
        </div>

        <div className='grid grid-cols-2 gap-4 md:gap-10'>
            <div className='sm:w-[200px] sm:h-[200px] w-[150px] h-[150px] shadow-lg p-5 rounded-xl flex flex-col items-center justify-center bg-orange-500 font-jakarta text-white font-medium hover:scale-105 duration-200 transition-all'>
              <MdAssignmentTurnedIn className='text-white text-[84px] mb-5 '/>
              <span className='text-center text-[14px]'>Exceptional Service</span>
            </div>

            <div className='sm:w-[200px] sm:h-[200px] w-[150px] h-[150px] shadow-lg p-5 rounded-xl  flex flex-col items-center justify-center font-mediumfont-jakarta hover:scale-105 duration-200 transition-all'>
              <GiWallet className='text-[84px] mb-5 hover:text-orange-500'/>
              <span className='text-center text-[14px]'>Competitive price</span>
            </div>

            <div className='sm:w-[200px] sm:h-[200px] w-[150px] h-[150px] shadow-lg p-5 rounded-xl  flex flex-col items-center justify-center font-medium font-jakarta hover:scale-105 duration-200 transition-all'>
              <RiTimerFlashFill className='text-[84px] mb-5 hover:text-orange-500'/>
              <span className='text-center text-[14px]'>Quick and stress free</span>
            </div>

            <div className='sm:w-[200px] sm:h-[200px] w-[150px] h-[150px] shadow-lg p-5 rounded-xl  flex flex-col items-center justify-center text-orange-500 font-medium font-jakarta hover:scale-105 duration-200 transition-all'>
              <BiHappyHeartEyes className='text-[84px] mb-5 '/>
              <span className='text-center text-[14px]'>We love our work</span>
            </div>
        </div>
    </div>
  )
}

export default Advantages