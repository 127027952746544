
import 'antd/dist/reset.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { WashKilaKitu, WashnFold } from './components';
import {Services, Pricing, Home, HomeService, LaundryCleaning, DryCleaning, CommercialCleaning} from './pages'

function App() {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/services' element={<Services/>} />
        <Route exact path='/home-pickup' element={<HomeService/>} />
        <Route exact path='/pricing' element={<Pricing/>} />
        <Route exact path='/services/laundry-cleaning' element={<LaundryCleaning/>} />
        <Route exact path='/services/dry-cleaning' element={<DryCleaning/>} />
        <Route exact path='/services/commercial-cleaning' element={<CommercialCleaning/>} />
        <Route exact path='/services/wash-kila-kitu' element={<WashKilaKitu/>} />
        <Route exact path='/services/wash-and-fold' element={<WashnFold/>} />
        {/* <Route exact path='/contact-us' element={<Contact/>} /> */}
        <Route path='/*' element={<Home/>} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
