import { faqs } from '../data';
import { Collapse } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { clients } from '../data';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const { Panel } = Collapse;


const Faq = () => {
  return (
    <div className='flex flex-col-reverse lg:flex-row px-10 sm:px-36 w-full lg:justify-between items-center lg:py-20'>
        <div className='py-5 lg:w-1/3'>
            <h3 className='text-3xl font-bold mb-5'>Frequently<span className='text-orange-500'> Asked </span>Questions</h3>
            <Collapse bordered={false} defaultActiveKey={['1']}>
                {faqs.map((faq,index) => (
                    <Panel header={faq.question} style={{color:"orange"}} key={faq.id}>
                    <p>
                        {faq.answer}
                    </p>
                </Panel>
                ))}
            </Collapse>

        </div>
        {/* <div className='grid grid-cols-2 gap-5 grid-rows-2 w-full lg:w-1/2 mt-10'>
            <img src="https://res.cloudinary.com/do3idyzar/image/upload/v1672910423/wash-woman-with-laundry-basket_o7rhdm.jpg" alt="wash happy man" className='h-[25vh] w-full object-cover rounded-lg shadow-md hover:scale-105 transition-all duration-200' />
            <img src="https://res.cloudinary.com/do3idyzar/image/upload/v1672910407/wash-towels_i8cz13.jpg" alt="wash happy man" className='h-[25vh] w-full object-cover rounded-lg shadow-md hover:scale-105 transition-all duration-200' />
            <img src="https://res.cloudinary.com/do3idyzar/image/upload/v1672910398/wash-folded-clothes_hw9lto.jpg" alt="wash pressing" className='h-[25vh] w-full object-cover rounded-lg shadow-md hover:scale-105 transition-all duration-200' />
            <img src="https://res.cloudinary.com/do3idyzar/image/upload/v1672910398/wash-laundry-man_udlgle.jpg" alt="wash pressing" className='h-[25vh] w-full object-cover rounded-lg shadow-md hover:scale-105 transition-all duration-200' />
        </div> */}

        <div className='flex flex-col p-8 sm:px-28 lg:mt-20 w-full lg:w-1/2'>
        <h2 className='font-extrabold text-[24px] lg:text-[36px]'>Our <span className='text-orange-600'>Commercial </span> clients</h2>
        <div className='mb-5'>       
            <Swiper
                // install Swiper modules
                modules={[Autoplay, Pagination]}
                spaceBetween={50}
                slidesPerView={2}
                autoplay={true}
                // pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                className='my-10'
                >
                {clients.map((client) => (
                    <SwiperSlide key={client.id} className='p-6 rounded-lg flex flex-col justify-center items-center'>
                        {/* <div className='text-[54px] text-center text-orange-400 p-5'>{client.}</div> */}
                        <img src={client.url} className="" width={300} height={300} alt="wash commercial client" />
                        {/* <p className='text-center font-jakarta text-[14px]'>{data.title}</p> */}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>

    </div>

    </div>
  )
}

export default Faq