// import {FaLocationArrow} from "react-icons/fa"
// import Iframe from 'react-iframe'
import { days, times } from "../data"

const Location = () => {
  return (
    <div className='flex flex-col lg:flex-row lg:justify-around p-10 sm:px-36 lg:py-5'>
        {/* <div className='flex w-full lg:w-1/2'> */}
            {/*  Map*/}
            {/* <Iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1578.3730485479057!2d36.70676255938085!3d-1.3235113676865704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1b332bb4415d%3A0xc4aaed7139d82b55!2sWash%20Dry%20Cleaners!5e0!3m2!1sen!2ske!4v1670245387000!5m2!1sen!2ske"
                allowFullcreen
                loading="lazy"
                title="Wash location"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-lg shadow-sm w-full lg:w-5/6 h-[60vh]"
                style={{border:0}}
            /> */}
        {/* </div> */}
        <div className='flex flex-col lg:flex-row justify-around items-center'>
            {/* Location text area */}
            {/* <div className='flex flex-col'>
                <h3 className='font-extrabold text-[30px] my-5'>Where to <span className="text-orange-500">find us.</span></h3> */}
                {/* Locations */}
                {/* <div className='flex justify-around items-center'>
                    <FaLocationArrow className="bg-orange-500 text-[72px] text-white rounded-full p-4" />
                    <div className="flex flex-col text-[14px w-3/5">
                        <p className="font-jakarta py-1">Crossroads Mall, Karen</p>
                        <p className="font-jakarta py-1">Remax Mall, Argwings Khodek Rd.</p>
                        <p className="font-jakarta py-1">105 Mummydadas, James Gichuru Rd.</p>
                    </div>
                </div> */}
            {/* </div> */}

            <img src="https://res.cloudinary.com/dhptro492/image/upload/v1697571424/washworkhours_pnjhdr.png" alt="wash" className="lg:w-2/5 w-full" />    

            {/* Working hours */}
            <div className='flex flex-col lg:w-2/5 w-full'>
                <h4 className='font-extrabold text-[30px] py-5 text-center'>Our working hours.</h4>
                <div className='bg-orange-500 rounded-lg shadow-lg p-5 flex items-center justify-around font-jakarta lg:py-10'>
                    <div className='text-white flex flex-col text-[14px]'>
                        {days.map((day, index) =>(
                            <h5 key={day.id} className='font-bold py-1'>{day.title}</h5>
                        ))}
                        
                        {/* <h5 className='font-bold py-1'>Tuesday</h5>
                        <h5 className='font-bold py-1'>Wednesday</h5>
                        <h5 className='font-bold py-1'>Thursday</h5>
                        <h5 className='font-bold py-1'>Friday</h5>
                        <h5 className='font-bold py-1'>Saturday</h5>
                        <h5 className='font-bold py-1'>Sunday</h5> */}
                    </div>
                    <div className='text-white flex-col text-[14px]'>
                        {/* <p className="pt-1">8AM -7PM</p> */}
                        {times.map((time, index) =>(
                            <p key={time.id} className=''>{time.title}</p>
                        ))}
                        {/* <p className="">8AM -7PM</p>
                        <p className="">8AM -7PM</p>
                        <p className="">8AM -7PM</p>
                        <p className="">8AM -7PM</p>
                        <p className="">8AM -7PM</p>
                        <p className="">8AM -7PM</p> */}
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Location