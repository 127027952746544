import React from 'react'
import { Link } from 'react-router-dom'
import { Footer, Navbarwhite } from '../components'
import { washFoldPricing } from '../data'
import {BsArrowLeftCircle} from 'react-icons/bs'

const WashnFold = () => {
  return (
    <>
        <Navbarwhite/>

        <div className='flex flex-col px-8 lg:px-28 justify-center items-center flex-wrap mt-20'>
            <div className='flex flex-col lg:flex-row lg:justify-between w-full mb-5 lg:mb-10'>
                <h2 className='font-extrabold text-[36px] '>Wash <span className='text-orange-500'>& Fold</span></h2>
                <div className='flex-col w-full lg:w-2/5'>
                    <p className='font-jakarta text-justify text-[16px]'>The ideal service if you want to avoid the dreaded laundry chore and save your time and sanity. We do your laundry for you so you can enjoy your free time.</p>
                    <p className='font-jakarta text-justify text-[16px]'>Whether it’s a weeks worth of the entire family’s clothes, or all your linens, we pick up, wash and fold your laundry.</p>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row justify-between w-full mt-5 lg:mt-10 gap-20 items-center'>
                {washFoldPricing.map((washfold, index) => (
                    <div key={washfold.id} className={`bg-orange-200 rounded-lg shadow-lg p-10 py-20 w-full  text-center font-jakarta mb-10`}>
                        <h4 className='font-bold mb-10 text-[16px]'>{washfold.title}</h4>
                        {washfold.pricing.map((data, index) =>(
                            <div key={data.id} className='mb-2 flex justify-between text-sm'>
                                <p className='font-medium text-[16px]'>{data.title}</p>
                                <p className='font-medium text-[16px]'>{data.price}</p>
                            </div>
                        ))}
                        <p className='font-bold text-[16px]'>{washfold.save}</p>
                </div>
                ))}
            </div>
            <Link to="/services" className="bg-orange-500 p-4 text-white text-[16px] font-jakarta rounded-lg hover:scale-105 transition-all duration-200 my-10 flex items-center">
                <BsArrowLeftCircle className="text-[20px] text-white mr-2"/>
                Back to Services
            </Link>
        </div>

        <Footer/>
    </>
  )
}

export default WashnFold