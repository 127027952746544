import {Navbarwhite, HomeServiceHero, HowItWorks, Footer, HomeServiceScehduler} from '../components'

function HomeService() {
  return (
    <div>
      <Navbarwhite/>
      <HomeServiceHero/>
      <HowItWorks/>
      <HomeServiceScehduler />
      <Footer/>
    </div>
  )
}

export default HomeService