import { dryCleaningHeroData } from "../data"
import { Link } from 'react-router-dom'
import {BsArrowLeftCircle} from 'react-icons/bs'

const DryCleaningHero = () => {
  return (
    <div className='flex flex-col justify-center items-center flex-wrap px-8 sm:px-28'>
    
    {dryCleaningHeroData.map((data, index) => (
        <div key={data.id} className='flex lg:justify-between flex-col-reverse py-5 md:flex-row w-full items-center'>
            <div className='mb-5 lg:w-2/5'>
                <h1 className='text-[36px] lg:text-[48px] my-8 lg:mb-8 font-extrabold text-orange-500 leading-[50px]'>Dry
                <span className='block text-black'> Cleaning.</span></h1>
                {data.content.map((p) => (
                    <p key={p.id} className='font-jakarta text-justify text-[14px]'>{p.paragraph}</p>
                ))}
            </div>
            <div className='mb-5'>
                <img src={data.url} className="rounded-lg shadow-xl object-cover lg:min-h-[70vh] min-h-[40vh] lg:max-w-[40vw] transition-all duration-500 hover:scale-105" alt="wash-services" />
            </div>
        </div>
    ))}
    <Link to="/services" className="bg-orange-600 w-1/6 p-4 text-white text-[16px] font-jakarta rounded-lg hover:scale-105 transition-all duration-200 my-10 flex justify-center items-center">
            <BsArrowLeftCircle className="text-[20px] text-white mr-2"/>
            Back to Services
        </Link>
    </div>
  )
}

export default DryCleaningHero