import Navbar from "./Navbar"

const Hero = () => {
  return (

    <div className='flex justify-start items-center flex-col h-screen'>
        <div className='relative w-full h-full'>
            {/* Hero section image and video */}
            <video src='https://res.cloudinary.com/do3idyzar/video/upload/v1672816758/wash_hero_video_dq3x5b.mp4' poster="https://res.cloudinary.com/do3idyzar/image/upload/v1672910396/wash-hero-thumbnail_aslh5q.jpg" autoPlay loop controls={false} muted className='w-full h-full object-cover'/>
            {/* Background overlay */}
            <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 p-5'>
                <Navbar/>
                {/* Hero information */}
                <div className=' absolute bottom-[10px] right-[5%] lg:right-[50px] lg:bottom-[20px] flex justify-center items-center mb-[20px]'>
                    <div className=' bg-black bg-opacity-40 rounded-xl px-10 py-5 p-4 lg:max-w-3xl flex lg:items-end items-center flex-col justify-center'>
                        {/* Main hero section text */}
                        <h1 className='text-[36px] text-center md:text-end font-bold lg:py-8 text-white md:text-5xl lg:text-[64px]  lg:border-b-[1px] lg:border-orange-400'>Nairobi's Finest <span className=' text-orange-500 block'>Drycleaners!</span></h1>
                        {/* Wash business description */}
                        
                        <p className='pt-[5px] lg:pt-[5px] font-jakarta mb-5 text-white font-normal text-[14px] md:text-lg sm:max-w-2xl text-justify leading-[20px]'>Wash Drycleaners offers premium and affordable Drycleaning and Laundry Services in Nairobi. We use the finest Italian machines, best technology and the purest of solvents to deliver impeccable results for everything from suits to designer couture.We offer pick up and drop services, commercial laundry service and express or same day service at no extra cost.</p>
                        {/* CTA Button */}
                        <div className=' bg-orange-500 rounded-lg flex items-center justify-center transition-all scale-105 duration-200 hover:bg-orange-600 ease-in-out p-1'>
                            <a href='#schedule' className=' font-medium p-3 text-[14px] text-white'>Schedule a wash</a>
                        </div>
                    </div>                   
                </div>
                
            </div>
        </div>
    </div>

  )
}

export default Hero