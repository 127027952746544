import { useFormik } from "formik"
import * as Yup from 'yup'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Schedule = () => {
    const formik = useFormik({
        initialValues:{
            fullname:"",
            email:"",
            phone:"",
            // package:"Wash Kila Kitu Essential",
            pickupdate:"",
            deliverydate:"",
            residence:"",
            garmentInstructions:"",
            deliveryInstructions:"",
        },
        // Form validation
        validationSchema: Yup.object({
            fullname: Yup.string()
                .max(50, 'Name must be 50 characters or less.')
                .required("Name is required"),
            email: Yup.string()
                .email('Invalid email address')
                .max(50, 'Email must be 50 characters or less.')
                .required("Email is required"),
            phone: Yup.string()
                .max(15, 'Phone number must be 15 characters or less.')
                .required("Phone number is required"),
            pickupdate: Yup.date()
                .required("Pickup date is required"),
            deliverydate: Yup.date()
                .required("Delivery date is required"),
            residence: Yup.string()
                .max(100, 'Residence must be 100 characters or less.')
                .required("Residence is required"),
            garmentInstructions: Yup.string()
                .max(300, 'Instructions must be 300 characters or less.'),
            deliveryInstructions: Yup.string()
                .max(300, 'Instructions must be 300 characters or less.'),
        }),

    })

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_1lzvaw9', 'template_aan36cs', form.current, 'OGAaE7DmuW-cNnuPe')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        formik.resetForm()
    }
    
    
  return (
    <div className='flex p-5 sm:px-28 justify-center items-center lg:py-10' id="schedule" >
        
        <div className='flex lg:w-1/2 z-10'>
            <form ref={form} onSubmit={sendEmail} className='p-10 w-full'>
                
                    <h3 className='font-extrabold  text-2xl text-center'>Schedule a <span className='text-fuchsia-600'>Wash?</span></h3>
                    <p className='font-jakarta text-[14px] py-2 text-center'>Whenever you're ready we come pick it up from you</p>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:gap-5'>
                        {/* Name */}
                        <div className="pb-2 w-full">
                            <label className={`font-jakarta block text-[14px] py-2 ${formik.touched.fullname && formik.errors.fullname ? "text-red-400": ""}`} htmlFor="fullname">{formik.touched.fullname && formik.errors.fullname ? formik.errors.fullname : "Full Name"}</label>
                            <input className='p-2 border-2 border-fuchsia-600 text-[14px] font-jakarta rounded-lg w-full' type="text" value={formik.values.fullname} onChange={formik.handleChange} onBlur={formik.handleBlur} name='fullname' placeholder='John Doe' required/>
                        </div>
                        {/* Email */}
                        <div className="pb-2 w-full">
                            <label className={`font-jakarta block text-[14px] py-2 ${formik.touched.email && formik.errors.email ? "text-red-400": ""}`} htmlFor="email">{formik.touched.email && formik.errors.email ? formik.errors.email : "Email"}</label>
                            <input className='p-2 border-2 border-fuchsia-600 font-jakarta text-[14px] rounded-lg w-full' type="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} name='email' placeholder='johndoe@domain.com' required />
                        </div>
                    </div>
                    {/*Phone Number */}
                    <div className="pb-2">
                        <label className={`font-jakarta block text-[14px] py-2 ${formik.touched.phone && formik.errors.phone ? "text-red-400": ""}`} htmlFor="phone">{formik.touched.phone && formik.errors.phone ? formik.errors.phone : "Phone Number"}</label>
                        <input className='text-[14px] p-2 border-2 border-fuchsia-600 font-jakarta rounded-lg w-full' type="text" value={formik.values.phone} onChange={formik.handleChange} onBlur={formik.handleBlur} name='phone' placeholder='+254 700 000 000' required />
                    </div>

                    <div className='flex flex-col lg:flex-row lg:justify-between lg:gap-5'>
                        {/* Pickup date */}
                        <div className="pb-2 w-full">
                            <label className={`font-jakarta block text-[14px] py-2 ${formik.touched.pickupdate && formik.errors.pickupdate ? "text-red-400": ""}`} htmlFor="pickupdate">{formik.touched.pickupdate && formik.errors.pickupdate ? formik.errors.pickupdate : "Pickup Date"}</label>
                            <input className='p-2 border-2 border-fuchsia-600 text-[14px] font-jakarta rounded-lg w-full' type="date" value={formik.values.pickupdate} onChange={formik.handleChange} onBlur={formik.handleBlur} name='pickupdate' required />
                        </div>
                        {/* Drop date */}
                        <div className="pb-2 w-full">
                            <label className={`font-jakarta block text-[14px] py-2 ${formik.touched.deliverydate && formik.errors.deliverydate ? "text-red-400": ""}`} htmlFor="deliverydate">{formik.touched.deliverydate && formik.errors.deliverydate ? formik.errors.deliverydate : "Delivery Date"}</label>
                            <input className='p-2 border-2 border-fuchsia-600 text-[14px] font-jakarta rounded-lg w-full' type="date" value={formik.values.deliverydate} onChange={formik.handleChange} onBlur={formik.handleBlur} name='deliverydate' required/>
                        </div>
                    </div>
                    {/* Pricing plan */}
                    {/* <div className="pb-2">
                        <label className='font-jakarta block text-[14px] py-2' htmlFor="package">Pricing package</label>
                        <select name="package" id="package" className='p-3 border-2 border-fuchsia-600 text-[14px] font-jakarta rounded-lg w-full' value={formik.values.package} onChange={formik.handleChange}>
                            <option value="Wash Kila Kitu Light" className="text-fuchsia-600">Wash Kila Kitu Light</option>
                            <option value="Wash Kila Kitu Essential" className="text-fuchsia-600">Wash Kila Kitu Essential</option>
                            <option value="Wash Kila Kitu Premier" className="text-fuchsia-600">Wash Kila Kitu Premier</option>
                            <option value="Wash Kila Kitu Advantage" className="text-fuchsia-600">Wash Kila Kitu Advantage</option>
                            <option value="Wash and Fold Essential" className="text-fuchsia-600">Wash and Fold Essential</option>
                            <option value="Wash and Fold Premier" className="text-fuchsia-600">Wash and Fold Premier</option>
                            <option value="Wash and Fold Advantage" className="text-fuchsia-600">Wash and Fold Advantage</option>
                            <option value="Custom" className="text-fuchsia-600">Customized plan</option>
                        </select>
                    </div> */}
                    {/*  Residence */}
                    <div className="pb-2">
                        <label className={`font-jakarta block text-[14px] py-2 ${formik.touched.residence && formik.errors.residence ? "text-red-400": ""}`} htmlFor="residence">{formik.touched.residence && formik.errors.residence ? formik.errors.residence : "Residence"}</label>
                        <textarea className='p-2 border-2 border-fuchsia-600 text-[14px] font-jakarta rounded-lg w-full' type="text" value={formik.values.residence} onChange={formik.handleChange} onBlur={formik.handleBlur} name='residence' placeholder='Nairobi Estate, Nairobi' required />
                    </div>
                    {/*  Garment Care instructions */}
                    <div className="pb-2">
                        <label className='font-jakarta block text-[14px] py-2' htmlFor="garmentInstructions">Garment care instructions(If any)</label>
                        <textarea className='p-2 border-2 border-fuchsia-600 text-[14px] font-jakarta rounded-lg w-full' type="text" value={formik.values.garmentInstructions} onChange={formik.handleChange} name='garmentInstructions' placeholder='eg. Do not use bleach' />
                    </div>
                    {/*  Delivery instructions */}
                    <div className="pb-2">
                        <label className='font-jakarta block text-[14px] py-2' htmlFor="deliveryInstructions">Delivery instructions(If any)</label>
                        <textarea className='p-2 border-2 border-fuchsia-600 text-[14px] font-jakarta rounded-lg w-full' type="text" value={formik.values.deliveryInstructions} onChange={formik.handleChange} name='deliveryInstructions' placeholder='eg. House number ' />
                    </div>
                    <h3 className="text-[20px} font-bold text-fuchsia-600">Terms & Conditions*</h3>
                    <p className="text-[14px] font-jakarta">Orders must be above Kshs.2,500</p>
                <button type="submit" className='p-4 hover:bg-fuchsia-500 bg-fuchsia-600 transition-all duration-200 text-white rounded-lg shadow-lg text-[14px] my-2 '>Schedule your Wash!</button>
            </form>
        </div>

    </div>
  )
}

export default Schedule