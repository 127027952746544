import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { commercialClients } from '../data';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom'
import {BsArrowLeftCircle} from 'react-icons/bs'

const CommercialClients = () => {
  return (
    <div className='flex flex-col p-8 sm:px-28 lg:mt-20'>
        <h2 className='font-extrabold text-[24px] lg:text-[36px]'>Our <span className='text-orange-600'>Commercial </span> clients</h2>
        <div className='mb-5'>       
            <Swiper
                // install Swiper modules
                modules={[Autoplay, Pagination]}
                spaceBetween={50}
                slidesPerView={4}
                autoplay={true}
                // pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                className='my-10'
                >
                {commercialClients.map((data) => (
                    <SwiperSlide key={data.id} className='p-6 rounded-lg flex flex-col justify-center items-center'>
                        <div className='text-[54px] text-center text-orange-400 p-5'>{data.icon}</div>
                        <p className='text-center font-jakarta text-[14px]'>{data.title}</p>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>

        <Link to="/services" className="bg-orange-600 w-1/6 p-4 text-white text-[16px] font-jakarta rounded-lg hover:scale-105 transition-all duration-200 my-10 flex justify-center items-center">
            <BsArrowLeftCircle className="text-[20px] text-white mr-2"/>
            Back to Services
        </Link>

    </div>
  )
}

export default CommercialClients